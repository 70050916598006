import {graphql, Link} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Kick-off, neem ons mee in de productvisie – Stap 1 van onze aanpak"
        description="Kick-off, neem ons mee in de productvisie – Stap 1 van onze aanpak."
        ogImage="kickoff.png"
        translatedPagePath="/en/approach/kick-off/">
        <Link to="/nl/werkwijze/prototyping/" className="body__nav body__nav--right" title="Aanpakstap 2. Prototype">
            Volgende stap
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Neem ons <br /> mee in de <br /> productvisie
                        </h2>
                        <div className="display__number">1</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Het proces begint met het ont&shy;dekken van wat we willen creëren. Tijdens de{' '}
                            kick-off-sessie bepalen we samen de rand&shy;voorwaarden voor het software&shy;product, op{' '}
                            basis van de product&shy;visie.
                        </p>
                        <p>
                            Om de essentie van het product te begrijpen beginnen we bij het samen beantwoorden van{' '}
                            vragen als: "Wie ben jij?", "Welk probleem moet het product oplossen?", "Wie zijn de{' '}
                            gebruikers en wat willen zij?". Samen maken we de{' '}
                            <span className="highlight">productvisie</span> tastbaar.
                        </p>
                        <p>
                            Gezamenlijk evalueren we de doelen en de ambities van het product. Daarbij stellen wij ons{' '}
                            kritisch op zodat we direct de belangrijkste{' '}
                            <span className="highlight">productrisico's</span> kunnen herkennen en vaststellen.
                        </p>
                        <p>
                            Met deze kennis schrijven we voor het product een{' '}
                            <span className="highlight">provisioneel ontwikkelplan</span>, een voorlopig ontwikkelschema{' '}
                            en stellen we de te behalen doelstellingen op. We starten vervolgens met de prototypefase{' '}
                            waarin we het plan verder concretiseren door de grootste onbekende factoren weg te nemen.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Samen om tafel</li>
                            <li className="ion-md-arrow-dropright">&#189; &ndash; 2 dagen</li>
                            <li className="ion-md-arrow-dropright">1 &ndash; 3 specialisten</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/nl/werkwijze/prototyping/"
                            name="Volgende stap: prototyping"
                            title="Aanpakstap 2. Prototype"
                        />
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
